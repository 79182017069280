import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ContactInfoContent from "../components/Contact/ContactInfo_1";
import Map from "../components/Contact/MapContent";
import CallToAction from "../components/callToAction/CallToAction_2";

function Contact() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Contact">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Contact"
          Subheader="Contact"
          bgimg={`${rpdata?.stock?.[11]}`}
          // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
          // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
          svgClass='svgTranspareHeader2'
        />

        <ContactInfoContent />

        <Map
          // para cambiar el svgclas busque la clase en el archivo svgStyles
          svgClass={'svgMap2'}
        />

        <div className="mb-20">
          <CallToAction
            title={rpdata?.dbSlogan?.[2]?.slogan}
            subtitle={rpdata?.dbSlogan?.[3]?.slogan}
            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
            bgImages={rpdata?.stock?.[10]}
          />
        </div>

      </div>
    </BaseLayout>
  );
}

export default Contact;
